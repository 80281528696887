// ----------------------------
// About Page
// ----------------------------

.about-avatars {
    display: table;
    width: 100%;
    padding: 100px 100px;
    text-align: center;
    color: $dark;

    h1 {
        font-size: 30px;
    }

    .about-card {
        opacity: 0.7;
        transition: all 250ms;

        &:hover {
            opacity: 1;
        }
    }

    .about-icon {
        width: 128px;
        height: 128px;
        border-radius: 64px;
        margin: auto;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.about-story {
    padding-bottom: 100px;

    .about-story-content {
        text-align: justify;
    }

    h1 {
        font-size: 30px;
    }
}
