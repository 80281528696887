// ----------------------------
// Section impress
// ----------------------------

section.impress {
    color: $dark;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: left;

    hr {
        border-color: #ccc;
    }

    h2 {
        margin-bottom: 40px;
    }

    h4 {
        font-weight: bold;
    }

    p {
        font-size: 16px;
        padding-bottom: 20px;
    }
}
