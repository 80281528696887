
// ----------------------------
// Variables
// ----------------------------

$danger: #F00022;
$success: #27ce65;
$primary: #03A9F5;
$warning: #096175;
$dark: #404040;
$light: #EFEFEF;
$background: #62bdfc;
$highlight: #28cb75;
$highlight_brand: #03a9f4;
$highlight_alt: #C7254E;
$mainFont: Roboto,Helvetica,Arial,sans-serif;
$textFont: "Open Sans",Helvetica,Arial,sans-serif;
$fixedFont: monospace;
$brandFont: "Maven Pro",Helvetica,Arial,sans-serif;
$darkBackground: #494f5a;

// Includes
@import 'about';
@import 'animations';
@import 'appstatus';
@import 'appstore';
@import 'button';
@import 'chrome';
@import 'console';
@import 'documentation';
@import 'get';
@import 'impress';
@import 'legal';
@import 'password-reset';
@import 'pricing';
@import 'social';
@import 'security';
@import 'personal';
@import 'business';
@import 'webhosting';
@import 'provider';
@import 'opensource';
@import 'brand-assets';

// ----------------------------
// Main elements
// ----------------------------

body {
    width: 100%;
    min-height: 100%;
    font-family: $textFont;
    color: $dark;
    background-color: white;
    font-size: 16px;

    &.hide-footer {
        margin-bottom: 0;
    }
}

.site-wrapper {
    min-height: 100vh;
    background: white;
    display: flex;
    flex-direction: column;
}

html {
    width: 100%;
    height: 100%;
}

section {
    padding-bottom: 30px;
    flex-grow: 2;
}

section > .row {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 10px 0 10px;
    font-family: $mainFont;
    color: black;
}

h2 {
    font-size: 26px;
}

p {
    margin: 0 0 10px;
    font-size: 18px;
    line-height: 1.5;
}

@media(min-width:767px) {
    p {
        margin: 0 0 10px;
        font-size: 20px;
        line-height: 1.6;
    }
}

a {
    color: $highlight_brand;

    &.disabled {
        cursor: default;
    }
}

a:hover,
a:focus {
    text-decoration: none;
    color: darken($highlight_brand, 10%);
}


// ----------------------------
// Bootstrap extension
// ----------------------------

.row-same-height {
    height: 100%;
    display: table;
    width: 100%;
}

.col-same-height {
    height: 100%;
    vertical-align: middle;
    display: table-cell;
    float: none !important;
}

.dropdown {
    a {
        &:before {
            display: none !important;
        }
    }
}

.dropdown-header {
    font-size: 20px;
    padding-bottom: 0;
}

// ----------------------------
// Navbar
// ----------------------------

.navbar {
    background-color: white;
    color: $dark;
    letter-spacing: 1px;
    font-family: $mainFont;

    a {
        color: $dark;
        font-weight: bold;

        &:hover {
            color: $highlight_brand;
        }
    }

    .nav {
        li.open > a,
        li.dropdown:hover > a,
        li a:hover,
        li a:focus,
        li.active {
            outline: none;
            background-color: white !important;
            color: $highlight_brand !important;
        }

        li.highlight a,
        li.highlight a:hover,
        li.highlight a:focus {
            font-weight: bold;
            color: white !important;
            background-color: $highlight_brand;
            border-radius: 20px 0px;
        }

        .caret {
            border-top-color: $dark;
            border-bottom-color: $dark;

            &:active,
            &:focus,
            &:hover {
                border-top-color: $highlight_brand;
                border-bottom-color: $highlight_brand;
            }
        }
    }

    ul.nav li.dropdown:hover > ul.dropdown-menu {
        display: block;
    }

    .navbar-brand {
        font-family: $brandFont;
        font-weight: normal;

        > img {
            height: 40px;
            margin-bottom: -6px;
            margin-top: -10px;
        }
    }

    .navbar-text {
        margin: 0 !important;

        &.highlight {
            border: solid $highlight 2px;
            border-radius: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
            margin-top: 10px !important;
            margin-bottom: 10px !important;
            font-weight: bold;
            background-color: $highlight !important;
            color: white !important;

            &:hover, &:focus {
                border: solid lighten($highlight, 10) 2px;
                background-color: lighten($highlight, 10) !important;
                color: white !important;
            }
        }
    }

    .navbar-toggle {
        padding: 4px 6px;
        font-size: 16px;
        color: $dark;

        &:focus,
        &:active {
            outline: none;
        }
    }
}

@media(min-width:767px) {
    .navbar {
        border-radius: 0;

        &> .container {
            max-width: 100%;
            padding-right: 0;
        }

        ul.nav li.dropdown:hover > ul.dropdown-menu {
            display: block;
        }

        // avoid top shadow when navbar already has one
        &.shadow .nav .dropdown-menu {
            box-shadow: 0 7px 6px 0px rgba(0,0,0,0.175);
        }

        .nav .dropdown-menu {
            border: none;
            width: 100%;
            border-radius: 0 0 5px 5px;

            // this centers the dropdown
            left: auto !important;
            right: -40% !important;

            li {
                a {
                    padding: 10px;
                    font-size: 16px;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

.navbar-fixed-top {
    border-width: 0;
}

.modal-dialog.demo {
    margin-top: 20%;
}


// .app-grid {
//     padding: 0 10px;
//     display: flex;
//     justify-content: space-around;
//     flex-wrap: wrap;
// }

// .app-grid-item {
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//     min-height: 80px;
//     min-width: 80px;
//     margin: 20px 0;
//     transform: scale(1);
//     opacity: 0.5;
//     transition: all 0.1s ease-in;

//     &:hover {
//         opacity: 1;
//         transform: scale(1.1);
//     }
// }


// ----------------------------
// Section title
// ----------------------------

section.title {
    display: table;
    width: 100%;
    padding: 0;
    padding-top: 8%;
    padding-bottom: 5%;
    color: $dark;
    background: white;

    h1 {
        margin-bottom: 20px;
    }

    h2 {
        font-size: 20px;
        color: $dark;
    }

    @media(max-width:991px) {
        margin-top: 80px;
    }

    .hero-image {
        width: 90%;

        @media(min-width:767px) {
            height: 400px;
            width: auto;
        }
    }
}

// ----------------------------
// Section why
// ----------------------------

section.why {
    background-color: white;
    padding: 20px 0;

    @media(min-width:767px) {
        padding: 20px 0px;
    }

    h3 {
        font-size: 28px;
        margin-top: 30px;

        @media(min-width:767px) {
            margin-bottom: 30px;
        }
    }

    p {
        font-size: 16px;
    }

    .row {
        @media(min-width:767px) {
            margin-bottom: 40px;
        }
    }
}

// ----------------------------
// Section app updates
// ----------------------------

.app-update-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 80px;
    margin-bottom: 40px;

    .app-update-item {
        img {
            width: 80px;
            height: 80px;
            margin-bottom: 5px;
        }

        p {
            font-size: 14px;
            line-height: 1;
        }
    }
}

// ----------------------------
// Section audience
// ----------------------------

section.audience {
    background-color: white;

    padding: 50px 0px;

    @media(min-width:767px) {
        padding: 100px 0px;
        padding-bottom: 200px;
    }

    h1 {
        margin-bottom: 20px;
    }

    h2 {
        font-size: 20px;
        margin-bottom: 40px;
    }

    p {
        font-size: 2rem;
    }

    .flex-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 30px;
    }

    .personal, .business, .webhosting {
        border-radius: 2px;
        overflow: hidden;
        text-align: center;

        .image {
            display: block;
            box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.176);
            width: 300px;
            height: 200px;
            background-size: cover;
            margin-bottom: 20px;
            transition: all 100ms ease-in;

            @media(min-width:767px) {
                margin-bottom: 40px;
            }

            &:hover {
                transform: scale(1.1);
            }
        }

        h3 {
            margin: 0;
            text-align: center;
            color: white;
            background: black;
            padding-top: 10px;
        }

        small {
            font-size: 12px;

            &.action {
                color: $highlight_brand;
            }
        }
    }

    .personal .image {
        background-image: url('/img/personal.jpg');
    }

    .business .image {
        background-image: url('/img/business.jpg');
    }

    .webhosting .image {
        background-image: url('/img/webhosting.jpg');
    }

    .personal {
        margin-bottom: 40px;

        @media(min-width:767px) {
            margin-bottom: 0;
        }
    }
}

// ----------------------------
// Section highlights
// ----------------------------

section.how {
    padding-bottom: 10px;

    h1 {
        text-align: center;
        padding-top: 50px;
    }
}

section.highlights {
    background-color: white;
    padding: 20px 0;

    @media(min-width:767px) {
        padding: 100px 0px;
    }

    &.alt {
        background-color: #f5f5f5;
    }

    p {
        font-size: 2rem;
    }

    &.columns {
        padding: 100px 0;

        h2 {
            @media(max-width:767px) {
                padding-top: 30px;
            }
        }
    }

    .screenshots {
        .image {
            height: 437px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        .slick-dots li button::before {
            font-size: 3rem;
        }
    }

    &.highlights-email {
        @media(max-width:993px) {
            padding-bottom: 350px;
        }
    }
}

// ----------------------------
// Section customers
// ----------------------------

section.customers {
    padding: 20px 0;
    text-align: center;

    @media(min-width:767px) {
        padding: 80px 0;
    }

    .customer-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 0;

        @media(min-width:767px) {
            padding: 0 50px;
        }

        a {
            padding: 0 15px;
            line-height: 100px;
        }
    }
}

// ----------------------------
// Section testimonials
// ----------------------------

section.testimonials {
    background-color: #f5f5f5;
    padding: 20px 0;
    text-align: left;

    @media(min-width:767px) {
        padding: 80px 0;
    }

    .testimonials-container {
        overflow: auto;
        width: 100%;
        display: flex;
        padding-bottom: 20px;

        .customer {
            display: inline-block;
            font-size: 16px;
            background-color: white;
            border-radius: 2px;
            box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.176);
            padding: 15px 25px;
            min-height: 380px;
            min-width: 350px;
            margin: 10px 20px;

            .logo {
                text-align: center;
                margin-bottom: 10px;
            }

            .quote {
                text-align: justify;
            }

            .author {
                padding-top: 10px;
                opacity: 0.7;
            }
        }

        .user {
            display: inline-block;
            font-size: 16px;
            background-color: white;
            border-radius: 2px;
            box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.176);
            padding: 15px 25px;
            min-width: 300px;
            margin: 10px 20px;

            .logo {
                text-align: left;
                border-radius: 40px;
                display: inline-block;
                vertical-align: middle;

                img {
                    height: 50px;
                    border-radius: 50%;
                }
            }

            .quote {
                margin-top: 20px;
                text-align: left;
                padding-bottom: 10px;
            }

            .author {
                display: inline-block;
                opacity: 0.7;
                padding-left: 10px;
                vertical-align: middle;
            }
        }
    }
}




// ----------------------------
// Footer
// ----------------------------

footer {
    position: relative;
    background-color: $darkBackground;
    width: 100%;
    color: white;
    font-size: 14px;
    padding: 0;
    margin: 0;
    text-align: center;
    padding-top: 100px;

    .collection {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        max-width: 1200px;
        text-align: left;
        flex-wrap: wrap;

        .list {

            h3 {
                color: darken($color: white, $amount: 30);
                margin-bottom: 20px;
            }

            a {
                display: block;
                color: white;
                margin-bottom: 10px;

                &:hover {
                    color: $highlight;
                }
            }
        }
    }
}


// ----------------------------
// Buttons
// ----------------------------

a.btn {
    text-decoration: none !important;
}

.btn {
    transition: all .1s ease-in-out;
    font-weight: 700;
    font-size: 16px;
    border-radius: 20px;
    // box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}

.btn-block {
    margin-top: 0 !important;
}

.btn-default {
    color: $dark !important;
    background-color: #f5f6f7;
    border: 1px solid white;

    &.btn-outline {
        background-color: transparent;
        color: $dark;
        border: 1px solid #d2d2d2;

        &:hover {
            color: $dark;
        }
    }

    &.active,
    &:focus,
    &:hover {
        background-color: $highlight_brand;
        border-color: transparent;
        color: white !important;
        box-shadow: none;
    }

    &:disabled {
        background-color: #f5f6f7 !important;
        color: $dark !important;
    }
}

.btn-primary {
    color: white !important;
    border: none;
    background-color: $primary;

    &.btn-outline {
        background-color: transparent;
        color: $dark;
        border: 1px solid white;

        &:hover {
            color: $dark;
        }
    }

    &:hover,
    &.active,
    &:focus {
        background-color: darken($highlight_brand, 10%);
        border-color: transparent;
        color: white;
        box-shadow: none;
    }
}

.btn-success {
    color: white !important;
    border: 1px solid darken($success, 15%);
    border: none;
    background-color: $success;
    text-decoration: none !important;

    &:hover,
    &.active,
    &:focus {
        background-color: darken($success, 10%);
    }
}

.btn-danger {
    border: none;
    color: white;
    background-color: $danger;

    &.btn-outline {
        background-color: transparent;
        border: 1px solid $danger;
        color: $danger;
    }

    &:hover,
    &.active,
    &:focus {
        background-color: darken($danger, 15%);
    }
}

.btn-warning {
    border: none;
    color: white;
    background-color: $warning;

    &.btn-outline {
        background-color: transparent;
        border: 1px solid $warning;
        color: $warning;
    }

    &:hover,
    &.active,
    &:focus {
        background-color: darken($warning, 15%);
    }
}


.btn-huge {
    padding: 25px;
    font-size: 26px;
}

.modal-content {
    border-radius: 2px;
}

.modal-header {
    border: none;
}

.modal-footer {
    margin-top: 10px;
    padding-top: 0;
    border: none;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: rgba(255,255,255,0.2);
}

textarea {
    resize: vertical;
}

.input-group-addon {
    color: $dark;
    background-color: white;
    border-radius: 0px;
}

.has-error .control-label {
    color: $danger;
}

.has-error .form-control, .has-error .form-control:focus {
    border-color: $danger;
}

.form-control {
    border: 1px solid #bbb;
    color: $dark;
    height: 34px;
    border-radius: 2px;

    &:focus {
        border-color: $primary;
    }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    cursor: not-allowed;
    opacity: 0.5;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}

.btn-group > .btn:last-child:not(:first-child) {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}


// ----------------------------
// Loader
// ----------------------------

.loader {
    color: $highlight_brand;
    display: inline-block;
    animation: fa-spin 2s infinite linear;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 2em;
    transform: translate(0, 0);
    text-rendering: auto;
}

.loader::before {
    content: "\f1ce";
}

.side-bar .fab, .side-bar .fas {
    width: 24px;
    text-align: center;
}

// https://epic-spinners.epicmax.co/
.hollow-dots-spinner, .hollow-dots-spinner * {
    box-sizing: border-box;
}

.hollow-dots-spinner {
    display: inline-block;
    height: 15px;
    width: calc(30px * 3);
}

.hollow-dots-spinner .dot {
    width: 15px;
    height: 15px;
    margin: 0 calc(15px / 2);
    // border: calc(15px / 5) solid #ff1d5e;
    border: calc(15px / 5) solid $highlight_brand;
    border-radius: 50%;
    float: left;
    transform: scale(0);
    animation: hollow-dots-spinner-animation 1500ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
    animation-delay: calc(500ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
    animation-delay: calc(500ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
    animation-delay: calc(500ms * 3);
}

@keyframes hollow-dots-spinner-animation {
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

// ----------------------------
// Unsorted
// ----------------------------

.site-body {
    display: table-cell;
}

.shadow {
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.176);
}

.text-small {
    font-size: 14px;
}

.text-success {
    color: $success;
}

.text-danger {
    color: $danger;
}

.text-blue {
    color: $highlight_brand !important;
}

.text-green {
    color: $success;
}

input[type="checkbox"] {
    margin-left: 0 !important;
}

.checkbox {
    text-align: left;
    padding-left: 16px;

    &> label {
        padding-left: 10px;
    }
}

.slick-container {
    display: block;
    max-width: 800px;
    height: 400px;
    margin: auto;

    @media(max-width:767px) {
        max-width: 300px;
        height: 200px;
    }
}

.terminal {
    font-family: $fixedFont;
    background: #222;
    color: white;
    border: none;
    max-width: 550px;
    padding: 10px;
    text-align: left;
    font-size: 13px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.hand {
    cursor: pointer;
}

// ----------------------------
// Tooltip styling
// ----------------------------

.tooltip {
    font-size: 16px;
    font-weight: bold;
}

.tooltip-inner {
    background-color: #03A9F4;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #03A9F4;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #03A9F4;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #03A9F4;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #03A9F4;
}


// ----------------------------
// Live Chat Widget
// ----------------------------

.rocketchat-widget {

    // hide live chat on mobile
    @media(max-width:767px) {
        display: none;
    }
}


// ----------------------------
// 404
// ----------------------------

section.notfound {
    display: flex;
    height: 100%;
    width: 100%;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
        font-size: 24px;
    }
}
