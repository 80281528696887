
// ----------------------------
// security page
// ----------------------------

section.security  {
    text-align: left;
    color: $dark;
    padding-top: 100px;
    padding-bottom: 100px;

    h1 {
        margin-bottom: 3rem;
    }

    h2 {
        margin-top: 40px;
    }

    a {
        color: $highlight_brand;
    }

    p {
        font-size: 16px;
        margin: 20px 0;
    }

}
