// ----------------------------
// Asset Page
// ----------------------------

.brand-assets-header {
    display: table;
    width: 100%;
    padding: 100px 100px;
    text-align: center;
    color: $dark;
}

.brand-assets {
    padding-bottom: 100px;

    h1 {
        font-size: 30px;
    }

    img {
        width: 100%;
        padding: 10px;
    }

    .container {
        display: flex;
        justify-content: space-evenly;
    }

    .asset-container {
        height: 250px;
        box-shadow: 0 2px 10px rgba(0,0,0,.1);
        border-radius: 2px;

        img {
            object-fit: contain;
            height: 200px;
            width: 300px;
            border-bottom: 1px #d6d3d3 solid;
        }
    }

    .asset-downloads {
        height: 50px;
        display: flex;

        a {
            display: flex;
            width: 50%;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #03a9f4;
                color: white;
            }
        }
    }
}

