// ----------------------------
// Section legal
// ----------------------------

section.legal {
    padding-top: 50px;
    padding-bottom: 100px;
    color: black;

    a {
        color: black;
        text-decoration: none;
        border-bottom: 1px dotted #2d2d2d;

        &:hover,
        &:focus {
            color: black;
        }
    }

    p {
        font-size: 16px;
        margin-bottom: 10px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 35px;
        margin-bottom: 10px;
    }

    h1 {
        font-size: 40px;
        margin-bottom: 35px;
    }

    h2,h3,h4,h5 {
        text-transform: none;
    }
}

