
// ----------------------------
// business page
// ----------------------------

section.business {
    text-align: center;
    padding-top: 130px;
    padding-bottom: 100px;

    &.hero {
        background-image: url('/img/business.jpg');
        background-size: cover;
        color: white;
        padding-bottom: 50px;

        @media(min-width:1500px) {
            background-position: 0 -221px;
        }

        h1 {
            color: white;
            text-shadow: -1px 1px 0 #888, 1px 1px 0 #888, 1px -1px 0 #888, -1px -1px 0 #888;
            margin-bottom: 40px;
            font-weight: bold;
        }

        h2 {
            color: white;
            font-size: 18px;
        }
    }


    p {
        font-size: 16px;
    }

    h2 {
        font-size: 26px;
    }

    h3 {
        font-size: 23px;
    }

    &.use-case {
        text-align: left;
        color: black;
        padding-top: 60px;
        padding-bottom: 60px;

        h1 {
            text-align: center;
            margin-bottom: 20px;
        }

        h2 {
            @media(max-width:767px) {
                margin-top: 40px;
            }
        }

        h3 {
            text-align: center;
            font-size: 18px;
            margin-bottom: 40px;
        }

        .app-examples {
            a {
                display: inline-block;
                width: 48px;
                height: 48px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin: 20px 0;
                margin-right: 20px;
                margin-left: 0;

                @media(min-width:767px) {
                    margin-right: 40px;
                }
            }
        }

        .use-case-row {
            @media(min-width:767px) {
                margin-bottom: 50px;
            }
        }
    }

    &.features {
        background-color: #f5f5f5;
        padding-top: 70px;
        text-align: left;

        i {
            color: darken($highlight_brand, 20);
        }


        h1 {
            margin-bottom: 60px;
            font-size: 32px;
        }

        h2 {
            padding-top: 15px;
            padding-bottom: 20px;
        }
    }
}


