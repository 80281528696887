
// ----------------------------
// Appstore page
// ----------------------------

.app-site {
    flex-grow: 2;

    .back {
        line-height: 40px;
        font-size: 23px;
        z-index: 1000;
        position: fixed;
        width: 50px;
        height: 100%;
        color: $dark;
        text-align: center;
        padding-top: 100px;
        background: rgba(0,0,0,0);
        transition: all 250ms;

        &:hover {
            color: white;
            background: rgba(0,0,0,0.5);
        }
    }

    section {
        display: table;
        width: 100%;
        padding: 25px 0;
        text-align: center;
        margin-top: 50px;
        color: $dark;
    }

    .main-column {
        @media(max-width:767px) {
            padding: 0;
        }
    }

    .details {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 60px;
        padding-right: 60px;
        width: 100%;
        margin: 0;
        background: white;
        color: $dark;
        text-align: left;
        border-radius: 2px;

        h1 {
            margin-top: 10px;
        }

        p {
            margin-bottom: 10px;
        }

        .icon {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 128px;
            height: 128px;
        }

        .title {
            display: inline-block;
            text-align: left;
            padding-left: 30px;

            div {
                font-size: 14px;
            }
        }

        .install {
            text-align: right;
            vertical-align: top;
            padding-top: 10px;
        }

        .slick-item {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 400px;

            @media(max-width:767px) {
                height: 200px;
            }
        }

        .col-same-height {
            @media(max-width:767px) {
                display: block;
                width: 100%;
                text-align: center;
            }
        }

        @media(max-width:767px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        .description {
            max-width: 100%;

            .markdown {
                max-width: 100%;
                font-family: $textFont;

                h1 {
                    font-size: 26px;
                }

                h2 {
                    font-size: 24px;
                }

                h3 {
                    font-size: 22px;
                }

                h4 {
                    font-size: 20px;
                }

                h5 {
                    font-size: 18px;
                }

                p {
                    font-size: 16px;
                }
            }
        }

        .changelog {
            background-color: #f2f2f2;
            border-radius: 2px;
            margin: 15px 0;
            padding: 5px;
            color: $dark;
            font-family: $textFont;

            @media(max-width:767px) {
                margin: 0;
            }

            p {
                font-size: 14px;
                line-height: 1;
            }

            h4 {
                font-weight: bold;
                font-size: 16px;
            }
        }
    }
}

.appstore {
    background-color: white;
    display: flex;
    padding: 25px 0;
    text-align: center;
    margin-top: 50px;
    color: $dark;

    .side-bar {
        overflow: scroll;
        min-width: 250px;
        padding: 0 10px;

        @media(max-width:767px) {
            display: none;
        }

        a {
            display: block;
            text-align: left;
            padding: 10px 20px;
            font-family: $textFont;
            font-weight: normal;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: $dark;

            &.active {
                color: #03a9f4;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .appstore-search-mobile {
        display: none;
        margin-bottom: 10px;

        @media(max-width:767px) {
            display: block;
        }
    }

    .appstore-grid {
        padding: 0 10px;
        flex-grow: 1;
    }

    .item {
        z-index: 0;
        width: 230px;
        position: relative;
        float: left;
        font-family: $textFont;
        margin: 0 8px 15px;
        padding: 0;
        background-color: #f2f2f2;

        @media(max-width:767px) {
            width: 95%;
            margin: 0 2.5% 15px
        }

        > div {
            height: 230px;
            color: $dark;
            overflow: hidden;
            transition: box-shadow .2s ease;

            &:hover {
                box-shadow: 0 0 20px rgba(0,0,0,.3);
            }

            .title {
                overflow: hidden;
                white-space: nowrap;
                font-size: 18px;
                margin: 5px;
            }

            .tagline {
                overflow: hidden;
                height: 46px;
                font-size: 12px;
                margin: 10px;
                margin-top: 0;
            }

            .icon {
                width: 100%;
                height: 140px;
                margin: auto;
                display: block;

                .icon-container {
                    width: 100px;
                    height: 140px;
                    margin: auto;
                    display: block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }
        }
    }
}
