// ----------------------------
// Hack for chrome's yellow background for autofills
// ----------------------------

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}
