// ----------------------------
// Main Documentation Page
// ----------------------------
section.docs-categories {
    display: table;
    width: 100%;
    height: 100%;
    padding-top: 80px;
    padding-bottom: 50px;
    text-align: center;

    .item {
        display: block;
        text-align: center;
        font-size: 20px;
        padding: 10px 0;

        &:hover>a {
            color: $highlight_brand;
        }

        a {
            width: 100%;
            height: 100%;
        }
    }

    h1 {
        padding-bottom: 30px;
    }
}


// ----------------------------
// Documentation Pages
// ----------------------------


.docs {
    @media (min-width: 979px) {
        height: 100vh;
        display: flex;
    }

    padding-top: 55px;
    padding-bottom: 0;

    a {
        color: #428BCA;
        text-decoration: none;

        &:hover,
        &:focus {
            color: black;
        }
    }

    scope {
        font-size: 14px;
        font-weight: bold;
        background-color: #aaa;
        color: white;
        padding: 2px;
        border-radius: 2px;
    }

    .toc {
        flex-grow: 0;
        overflow: auto;
        padding: 20px 0;
        padding-right: 10px;
        direction: rtl;
        min-width: 250px;

        &>ul {
            direction: ltr;
        }
    }

    .content {
        flex-grow: 1;
        overflow: auto;
        padding: 20px 0;
        max-height: 100%;

        p {
            margin-bottom: 10px;
            font-size: 16px;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-top: 20px;
            margin-bottom: 10px;
            font-weight: bold;
        }

        h1 {
            font-size: 30px;
            margin-bottom: 20px;
        }

        h2 {
            font-size: 20px;
        }

        h3 {
            font-size: 16px;
        }

        h4 {
            font-size: 14px;
            margin-top: 10px;
            margin-bottom: 5px;
        }

        h2,h3,h4,h5 {
            text-transform: none;
        }
    }
}
