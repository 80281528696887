
// ----------------------------
// pricing page
// ----------------------------

section.pricing-faq {
    max-width: 700px;
    margin: auto;

    h3 {
        padding-top: 80px;
        margin-top: -60px;
    }
}

section.pricing {
    text-align: center;
    color: $dark;
    padding-top: 100px;

    &.pricing-enterprise {
        padding-top: 20px;
    }

    .period-selector {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    p {
        font-size: 16px;
    }

    &.alt {
        background-color: #f5f5f5;
    }

    small {
        font-size: 10px;
        font-weight: normal;
    }

    .plans {
        display: flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .plan-card {
        margin: 20px;
        margin-bottom: 40px;
        border-radius: 20px;
        width: 350px;
        // height: 300px;
        padding-bottom: 20px;
        box-shadow: 0 1px 15px rgba(27,31,35,.15) !important;

        @media(max-width:767px) {
            min-width: 90%;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        h3 {
            padding-bottom: 20px;
            margin-bottom: 0;
            font-size: 16px;
        }

        p {
            padding-left: 20px;
            padding-right: 20px;
            // padding-bottom: 20px;
            margin-bottom: 0;
        }

        h2 {
            font-weight: bold;
            background-color: #55caff;
            margin: 0;
            margin-bottom: 20px;
            color: white;
            padding: 16px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }

        &.free {
            .price {
                margin: 7px 0;
            }

            h2 {
                color: $dark;
                background-color: #f6f8fa;
            }
        }

        &.business {
            h2 {
                background-color: #3a8cb1;
            }
        }

        &.enterprise {
            h2 {
                background-color: #265f79;
            }
        }

        .price {
            text-align: center;
            padding-bottom: 20px;
            line-height: 13px;

            .contact-us {
                background-color: #265f79;
                margin: 30px 0;
            }

            h3 {
                margin: 0;
                padding: 0;
                margin-bottom: 10px;
                font-size: 55px;

                span {
                    font-size: 24px;
                }
            }
        }
    }
}
