// ----------------------------
// Animations
// ----------------------------

.animation-rise {
    animation-duration: 3s;
    animation-name: rise;
}

.animation-hueify {
    animation-duration: 10s;
    animation-name: hueify;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes hueify {
    0% {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(180deg);
    }

    100% {
        filter: hue-rotate(0deg);
    }
}

@keyframes rise {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
