
// ----------------------------
// get page
// ----------------------------
section.install, section.provider, section.managed, section.images, section.referral  {
    text-align: center;
    color: $dark;
    padding-bottom: 20px;

    h1 {
        margin-bottom: 3rem;
    }

    a {
        color: $highlight_brand;
    }

    p {
        font-size: 16px;
    }

    .images-grid {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0 50px;

        @media(min-width:767px) {
            padding: 0 150px;
        }

        .provider-logo {
            display: flex;
            margin: 10px;
            filter: grayscale(100%);

            &:hover {
                filter: grayscale(0%);
            }
        }
    }

    .provider-grid {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0 50px;

        @media(min-width:767px) {
            padding: 0 150px;
        }

        .provider-logo {
            display: flex;
            margin: 10px;
            filter: grayscale(100%);

            &:hover {
                filter: grayscale(0%);
            }
        }
    }

    .referral-grid {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0 50px;
        max-width: 1280px;

        .provider-logo {
            img {
                margin: 20px;
            }
        }
    }
}

.signup-promo-code {
    width: 180px;
    font-family: monospace;
    font-size: 20px;
    border: none;
    background-color: #626262;
    color: white;
    border-radius: 2px;

    input {
        border: none;
        color: white;
        background-color: transparent;
        font-family: monospace;
        font-size: 18px;
        width: 125px;
    }

    span {
        float: right;
        margin-right: 10px;
        cursor: pointer;
    }
}

section.install {
    padding-top: 100px;
}

section.images {
    padding-top: 20px;
}

section.provider {
    padding-bottom: 100px;
}
