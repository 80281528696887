// ----------------------------
// Console
// ----------------------------

.console {
    display: table;
    width: 100%;
    height: 100%;
    padding: 100px 0;
    text-align: center;
    background: white;
    color: $dark;

    a {
        text-decoration: none;
    }
}

.subtext {
    color: rgb(104, 104, 104);
    font-size: 12px;
}

// User profile
.user-profile {
    text-align: left;

    .email-verification-banner-danger {
        background-color: rgba($danger, 0.7);
        color: white;
        padding: 10px;
        margin-bottom: 30px;
        text-align: center;
        cursor: pointer;

        &:hover {
            background-color: rgba(darken($danger, 10%), 0.7);
        }
    }

    .email-verification-banner-success {
        background-color: rgba($success, 0.7);
        color: white;
        padding: 10px;
        margin-bottom: 30px;
        text-align: center;
    }

    a {
        &:hover:before,
        &:focus:before {
            visibility: visible;
            opacity: 1;
            transform: scaleX(1);
        }

        &:before {
            visibility: visible;
            opacity: 0.5;
            transform: scaleX(0.8);
        }
    }

    .menu-item {
        cursor: pointer;

        &.active {
            font-weight: bold;
            color: $primary;
        }

        &:hover {
            color: $primary;
        }
    }

    .content {
        p {
            font-size: 16px;
        }
    }

    table {
        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.credit-card {
    border: solid 1px;
    border-color: #aaa;
    border-radius: 5px;
    max-width: 500px;
    padding: 10px;
    text-align: left;
    background-color: #f4f4f4;

    .form-control {
        background: white;
        border-radius: 0;
        border-color: #aaa;

        &.has-error {
            border-color: $danger;
        }
    }

    .year {
        width: 100px;
    }

    .month {
        width: 150px;
    }

    .cvc {
        width: 50px;
    }

    .number {
        width: 100%;
    }

    .name {
        width: 100%;
    }

    .form-container {
        display: inline-block;
    }

    p {
        font-size: 16px;
        text-align: left;
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
    }
}

img.round {
    border-radius: 5px;
}

img.round-lg {
    border-radius: 10px;
}


.balance {
    font-weight: bold;
    margin: 20px 0;
}

.form-control.half {
    display: inline-block;
    width: 49%;
}

.footnote {
    font-size: 14px;
    opacity: 0.6;

    &:hover {
        opacity: 1;
    }
}

label {
    font-weight: normal;
}

.form-group-inline {
    display: flex;
}

.subscription-setup {
    width: 700px;

    .payment-provider-info {
        font-size: 12px;
    }

    .control-label {
        font-weight: bold;
    }

    .review-table {
        border: none;
        width: 300px;

        td:last-of-type {
            text-align: right;
        }
    }

    .plans {
    }

    .plan-card {
        display: flex;
        margin: 10px;
        border-radius: 5px;
        max-width: 700px;
        padding: 10px;
        box-shadow: 0 1px 15px rgba(27,31,35,.15) !important;
        color: $dark !important;
        padding-left: 20px;
        padding-right: 20px;

        @media(max-width:767px) {
            min-width: 90%;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        &.active {
            box-shadow: 0 1px 15px $primary !important;

            background-color: $primary;

            h2, h3, p, small {
                color: white;
            }
        }

        &:hover {
            box-shadow: 0 1px 15px #bbb !important;
        }

        h2 {
            font-weight: bold;
        }

        h3 {
            font-size: 20px;
        }

        p {
            font-size: 12px;
        }

        small {
            font-size: 10px;
        }

        .plan-card-info {
            flex-grow: 1;
        }

        .plan-card-price {
            text-align: center;
            width: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h3 {
                margin: 0;
                padding: 0;
                font-size: 55px;

                span {
                    font-size: 24px;
                }
            }
        }
    }

    .pricing-tier-container {
        display: flex;
        justify-content: space-evenly;
    }

    .pricing-tier {
        text-align: left;
        border-radius: 2px;
        color: $dark;
        border: 1px solid #b0b0b0;
        padding: 20px;
        width: 280px;
        cursor: pointer;

        &:hover {
            background-color: #03a9f512;
            border: 1px solid #03a9f5;
        }

        &.selected {
            background-color: #03a9f512;
            border: 2px solid #03a9f5;
        }

        h2 {
            font-size: 24px;
            font-weight: bold;
        }

        h3 {
            font-size: 42px;
        }

        p {
            font-size: 14px;
            margin: 0 4px;
        }

        small {
            font-size: 10px;
            font-weight: 400;
        }
    }

    .price {
        text-align: center;
        padding: 20px;
        padding-left: 66px;
        border-radius: 5px;

        h3 {
            margin: 0;
            font-size: 70px;

            span {
                font-size: 24px;
            }
        }

        small {
            font-size: 10px;
        }
    }

    .features {
        p {
            text-align: left;
            font-size: 14px;
            margin-left: 30px;

            i {
                padding-right: 5px;
            }
        }
    }
}

.referral {

    h3 {
        margin-top: 20px;
    }

    .code-box {
        color: white;
        background-color: #03a9f4;
        padding: 15px;
        box-shadow: 0 0 10px rgba(146, 146, 146, 0.5);
        width: 600px;
        margin: 30px 0px;

        h4 {
            color: white;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .referral-code {
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            background-color: rgba(0,0,0,0.25);
            color: white;
            position: relative;

            input {
                width: 100%;
                border: none;
                color: white;
                background-color: transparent;
                font-family: monospace;
                font-size: 18px;
            }

            span {
                position: absolute;
                right: 10px;
                font-size: 20px;
                cursor: pointer;

                &:hover {
                    color: #e8e8e8;
                    transform: scale(1.2);
                }
            }
        }
    }
}

.invoice-payment {
    text-align: center;
    color: white;
    background-color: #27ce65;
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;

    &.success {
        background-color: #27ce65;
    }

    &.failed {
        background-color: rgba(240,0,34,.7);
    }
}

