
// ----------------------------
// provider pages
// ----------------------------
section.provider-title {
    padding-top: 100px;
    padding-bottom: 50px;
}

section.provider-facts {
    padding-top: 30px;
    padding-bottom: 30px;

    .provider-facts-logo {
        width: 100px;
        margin: 0 50px;
    }

    ul {
        vertical-align: middle;
        display: inline-block;
        list-style-type: none;
        font-size: 20px;
    }
}

section.provider-cloudron {
    padding-top: 50px;
    padding-bottom: 50px;

    h3 {
        font-weight: bold;
        margin-top: 30px;
    }

    table {
        font-size: 20px;
        margin: 30px 0;
    }

    thead {
        border-bottom: 1px solid;
    }

    th {
        padding: 10px 0;
    }

    td {
        padding: 5px 0;
    }

    .table-cell-check {
        text-align: center;
    }
}

section.provider-video {

    h3 {
        font-weight: bold;
    }
}

section.provider-get-started {
    margin: 100px 0;
}
