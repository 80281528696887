// ----------------------------
// Button page
// ----------------------------

section.button {
    text-align: center;

    &.header {
        padding-top: 150px;
        padding-bottom: 100px;

        p {
            font-size: 16px;

            i {
                color: $primary;
                padding-right: 8px;
            }

            a {
                color: $primary;
            }
        }
    }

    &.why {
        background-color: #f5f5f5;
        padding-top: 100px;
        padding-bottom: 100px;

        a {
            color: $primary;

            &.btn-primary {
                color: white;
            }
        }
    }
}
