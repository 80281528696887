
// ----------------------------
// opensource page
// ----------------------------

section.opensource {
    text-align: center;
    color: $dark;
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 50px;

    h1 {
        margin-bottom: 3rem;
    }

    a {
        color: $highlight_brand;
    }

    p {
        font-size: 16px;
        max-width: 500px;
        text-align: justify;
        margin: auto;
    }
}

section.partner-faq {
    text-align: left;
    color: $dark;
    margin-top: 50px;
    padding-bottom: 50px;

    h3 {
        margin-top: 4rem;
    }

    a {
        color: $highlight_brand;
    }

    p {
        font-size: 16px;
        max-width: 500px;
        text-align: justify;
        margin: auto;
    }
}

section.opensource-sponsoring {
    padding-top: 30px;
    background-color: #f5f5f5;

    hr {
        border-top-color: #aaa;
    }

    .opensource-sponsoring-item {
        padding: 5px;

        div {
            padding: 5px 20px;
            background-color: white;
            border: 1px solid #e6e6e6;
            border-radius: 4px;

            h3 {
                font-size: 20px;

                img {
                    height: 60px;
                    margin-top: -4px;
                    float: right;
                }
            }

            hr {
                border-top-color: #e6e6e6;
            }

            p {
                font-size: 14px;

                a {
                    white-space: nowrap;
                }
            }

            p:first-of-type {
                height: 44px;
            }
        }
    }
}
