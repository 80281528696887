// ----------------------------
// Social Media Buttons
// ----------------------------

.social-action-container {
    display: none;
    position: fixed;
    right: 20px;
    top: 30%;

    @media(min-width:767px) {
        display: block;
    }
}

.social-link {
    height: 32px;
    width: 32px;
    display: inline-block;
    margin: 10px;
    filter: saturate(0%);
    background-size: contain;
    background-repeat: no-repeat;
}

.social-link:hover ,.social-link:focus {
    filter: saturate(100%);
}

.social-link.twitter {
    background-image: url('/img/social/twitter.svg');
}

.social-link.linkedin {
    background-image: url('/img/social/linkedin.svg');
}

.social-link.github {
    background-image: url('/img/social/github.svg');
}

.social-link.rss {
    background-image: url('/img/social/rss.svg');
}

.social-link.email {
    background-image: url('/img/social/email.svg');
}

.social-link.chat {
    background-image: url('/img/social/chat.svg');
}

.social-link.website {
    background-image: url('/img/social/website.svg');
}

.social-link.mastodon {
    background-image: url('/img/social/mastodon.svg');
}
