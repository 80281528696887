
// ----------------------------
// appstatus page
// ----------------------------

section.appstatus {
    padding: 100px 0;

    table {
        thead {
            background-color: $primary;
            color: white;
        }

        thead td,tbody td:first-of-type {
            font-weight: bold;
            border-left: none;
        }

        tbody td:last-of-type {
            border-right: none;
        }

        td {
            padding: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        tr {
            cursor: pointer;
        }

        tbody td {
            border-style: solid;
            border-color: #eee;
            border-width: 0 0 1px 0;
            line-height: 40px;

            img {
                width: 32px;
                max-width: 32px;
                max-height: 32px;
            }
        }

        tbody tr:hover {
            background-color: #eee;
        }
    }
}
